import {
  ADD_ORDER,
  CONFIRM_SELECTION,
  DATA_CONFIG_LOADED,
  FILTER_STEPS_DATA,
  TOGGLE_RESULT_MODAL,
  STEP_DATA_RECEIVE,
  SUBMIT_RESULT,
  RESET_CONFIGURATOR,
} from "../actions/configurator";

import { resetCodes } from "../../components/partials/ConfiguratorParts";

const INITIAL_STATE = {
  id: null,
  json: null,
  confTitle: null,
  resultModal: false,
  cartModal: false,
  form: false,
  tempTitle: "",
  tempMultiple: null,
  tempItemsList: [],
  tempSubStep: [],
  resultItemsList: [],
  stepIndex: 0,
  steps: [],
  subSteps: [],
  activeSteps: [],
  options: [],
  serial_masks: [],
  filled_mask: [],
  groups: {
    group0: { id: null, name: "", code: "" },
    group1: { id: null, name: "" },
    group2: { id: null, name: "" },
    group3: { id: null, name: "" },
    group4: { id: null, name: "" },
    group5: { id: null, name: "" },
    group6: { id: null, name: "" },
    group7: { id: null, name: "" },
    group8: { id: null, name: "" },
    group9: { id: null, name: "" },
    subGroup: { id: null, name: "", code: "" },
  },
  serial: [],
  codes: {
    group0: "",
    group1: "",
    group2: "",
    group3: "",
    group4: "",
    group5: "",
    group6: "",
    group7: "",
    group8: "",
    group9: "",
    subGroup: "",
  },
  stepValues: [], // { index: number, value: string, step_id: number, code_index: number }
  model: {
    price: 0,
    price_with_tax: 0,
    image: null,
  }
};

const configurator = (state = Object.assign({}, INITIAL_STATE), action) => {
  switch (action.type) {
    case STEP_DATA_RECEIVE:
      let serial: any = state.serial;
      let filled_mask: string[] = state.filled_mask;

      action.payload.result.data.forEach((el: any) => {
        serial[el.index] = {
          value: el.serial_value,
          step_id: el.configurator_step_id,
        }
      })


      let result = action.payload.result.data[0];
      const index = action.payload.index;
      const codes = { ...state.codes };
      let stepValues: any = [...state.stepValues];

      if (result) {
        codes[`group${result.index}`] = result.value;
      } else {
        result = {
          value: state.groups[`group${index}`].name,
        }

        if (state.activeSteps[index]) {
          // @ts-ignore
          result['configurator_step_id'] = parseInt(state.activeSteps[index].id)
        }
      }

      const stepIndex = stepValues.findIndex((v: any) => v.index === index);

      if (stepIndex === -1) {
        stepValues.push({
          index,
          value: result.value,
          step_id: result.configurator_step_id,
          option_id: action.payload.selectedOptionId,
          code_index: result.index,
        });
      } else if (stepValues[stepIndex + 1] ) {
        stepValues[stepIndex].value = result.value;
        stepValues[stepIndex].step_id = result.configurator_step_id;
        stepValues[stepIndex].option_id = action.payload.selectedOptionId;
        stepValues[stepIndex].code_index = result.index;

        const length = stepValues.length;

        for (let i = stepIndex + 1; i < length; i++) {
          // reset stepValues and serial
          stepValues = stepValues.filter(item => item.index != i)
          console.log('stepValues', stepValues)
          console.log('serial', serial)

          const stepIds = stepValues.map(s => s.step_id)

          serial.forEach((el, index) => {
            if (stepIds.includes(el.step_id) == false) {
              delete serial[index]
            }
          })
        }
      } else {
        stepValues[stepIndex].value = result.value;
        stepValues[stepIndex].step_id = result.configurator_step_id;
        stepValues[stepIndex].option_id = action.payload.selectedOptionId;
        stepValues[stepIndex].code_index = result.index;
      }

      // @ts-ignore
      const currentMask: String = state.serial_masks.filter((el: string) => el.startsWith(state.serial[0]?.value))[0]

      if (currentMask) {
        filled_mask = currentMask.split(/\.|-|\//)

        for (let i = 0; i < serial.length; i++) {
          const ch = filled_mask[i] ? new Array(filled_mask[i].length + 1).join('X') : 'X'
          filled_mask[i] = serial[i]?.value || ch
        }

      } else {
        console.log('Mask not found')
      }

      ////////////////////////////

      const findOption = (id) => {
        return state.options.find((item: any) => {
          if (parseInt(item.id) === parseInt(id) )  {
            return item
          }
        });
      }

      const findDependentOptions = (option_id: string): object => {
        const options: object = {};

        state.options.forEach((item: any) => {
          if (parseInt(item.id) === parseInt(option_id) )  {
            item.relationships.dependent_options.data.forEach(option => {
              option = findOption(option.id);
              options[option.attributes.configurator_step_id] ||= [];

              options[option.attributes.configurator_step_id].push(option.id)
            });
          }
        });

        return options;
      }

      const selectedOptionIds = stepValues.map(value => value.option_id)

      let activeSteps = state.activeSteps;
      const skippedSteps: any = [];

      state.steps.forEach((step: any) => {
        let visibleOptions: any[] = [];

        step.relationships.configurator_options.data.forEach(option => {
          const dependent_options = findDependentOptions(option.id);
          const existOptions = Object.values(dependent_options).every(arrayOptions => {
            return arrayOptions.some(element => {
              return selectedOptionIds.includes(element);
            });
          });

          if (existOptions) {
            visibleOptions.push(findOption(option.id));
          }
        });

        if (visibleOptions.length === 1 && visibleOptions[0]?.attributes?.value === "-1") {
          skippedSteps.push(step.id)
        }
      });

      activeSteps = state.steps.filter((item: any) => !skippedSteps.includes(item.id))

      return {
        ...state,
        codes,
        serial,
        filled_mask,
        stepValues,
        activeSteps,
      };
    case DATA_CONFIG_LOADED:
      const steps = action.payload.included.filter(item => item.type === 'configurator_steps')
      const subSteps = action.payload.included.filter(item => item.type === 'configurator_substeps')
      const options = action.payload.included.filter(item => item.type === 'configurator_options')
      const serial_masks = action.payload.data.attributes.serial_masks;

      // @ts-ignore
      if (!!state.json && state.json.data.id !== action.payload.data.id) {
        return {
          ...INITIAL_STATE,
          json: action.payload,
          confTitle: action.payload.data.attributes.name,
          steps,
          subSteps,
          activeSteps: steps,
          options,
          serial_masks
        };
      }
      return {
        ...state,
        json: action.payload,
        confTitle: action.payload.data.attributes.name,
        steps,
        subSteps,
        activeSteps: steps,
        options,
        serial_masks
      };
    case FILTER_STEPS_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CONFIRM_SELECTION:
      if (action.payload.stepIndex < state.stepIndex) {
        let groups = state.groups;
        let codes = state.codes;
        let stepIndex = action.payload.stepIndex;

        for (let i = stepIndex; i <= 7; i++) {
          groups[Object.keys(groups)[i]] = { id: null, name: "" };
        }

        codes = resetCodes(state, stepIndex);

        return {
          ...state,
          ...action.payload,
          groups: { ...groups },
        };
      }

      return {
        ...state,
        ...action.payload,
      };
    case ADD_ORDER:
      return {
        ...state,
        ...action.payload,
      };
    case TOGGLE_RESULT_MODAL:
      state.stepValues = state.stepValues.filter((item: any) => item.index < state.stepIndex)

      // filter example: 'group1' < 'group2' => true
      const codeKeys = Object.keys(state.codes).filter(item => item < `group${state.stepIndex}`)

      state.codes = codeKeys.reduce((codes, key) => {
        return {
          ...codes,
          [key]: state.codes[key]
        };
      }, INITIAL_STATE.codes);

      return {
        ...state,
        resultModal: action.payload,
      };
    case SUBMIT_RESULT:
      return {
        ...state,
        model: action.payload || INITIAL_STATE.model,
      };
    case RESET_CONFIGURATOR:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default configurator;
